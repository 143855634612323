import React, { useEffect } from "react";
import "./App.css";

import {
  Card,
  WixDesignSystemProvider,
  Button,
  MarketingLayout,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { dashboard } from "@wix/dashboard";
import { createClient } from "@wix/sdk";
import * as Icons from "@wix/wix-ui-icons-common";
import { useTranslation } from "react-i18next";
import i18n from './i18n';

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const APPS: any = {
  "32f8605b-3c95-447a-9f2c-4e6352ba3e48": {
    baseUrl: "https://certifiedcode.editorx.io/ecpay-pay-button/_functions"
  },
  "52cd0660-060b-4139-b66b-d6fbeb562d2f": {
    baseUrl: "https://certifiedcode.editorx.io/newebpay-pay-button/_functions"
  },
  "4151fc7d-953a-4514-909c-eb606d1ef15a": {
    baseUrl: "https://certifiedcode.wixsite.com/tappay-pay-button/_functions"
  }
}

function App() {
  const [client, setClient] = React.useState(null as any);
  const { t, i18n } = useTranslation();
  const instance = new URLSearchParams(window.location.search).get("instance");

  const appId = new URLSearchParams(window.location.search).get("appId");
  const baseUrl = APPS[appId!].baseUrl;

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);


  useEffect(() => {
    try {
      if (inIframe()) {
        var c = createClient({
          host: dashboard.host(),
          auth: dashboard.auth(),
          modules: {
            dashboard,
          },
        });
        setClient(c);
      }
    } catch (error) {
      throw error;
    }
    
  }, []);

  useEffect(() => {
    setInstance()
  }, [baseUrl])

  function setInstance() {
    fetch(baseUrl + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
      })
      .catch(() => {});
  }

  const isAlt = (instanceData?.site?.ownerEmail || "").endsWith("@wixtw.com")
  const isUpgraded = instanceData?.instance?.isFree === false;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      {isUpgraded ? <Card>
        <MarketingLayout
          size="tiny"
          title={"我們能為你提供協助"} 
          description={"與我們的專家了解如何使用 支付按鈕 以及其他 Certified Code 服務。"}
          actions={
            <Button
              size="small"
              skin="ai"
              suffixIcon={<Icons.AIFilledSmall />}
              as={"a"}
              href={`https://calendly.com/certifiedcode/flows-partners?utm_source=tw-mkt&utm_medium=referral&utm_campaign=tw-mkt`}
              target={"_blank"}
            >
              {"預約通話"}
            </Button>
          }
        />
      </Card>
      :<Card>
        <MarketingLayout
          size="tiny"
          title={isAlt ? "轉移至 Certified Code 提供的台灣金流物流整合" : "了解 Certified Code 提供的台灣金流物流整合"} 
          description={isAlt ? "轉移後，每年可節省高達數萬元。無需綁定合約，隨時取消。" : "與我們的專家探索整合台灣最受歡迎的金流和物流服務的最佳方式。"}
          actions={
            <Button
              size="small"
              skin="ai"
              suffixIcon={<Icons.AIFilledSmall />}
              as={"a"}
              href={`https://calendly.com/certifiedcode/flows-partners?utm_source=tw-mkt&utm_medium=referral&utm_campaign=tw-mkt`}
              target={"_blank"}
            >
              {"預約諮詢"}
            </Button>
          }
        />
      </Card>}
      {/* </SidePanel> */}
    </WixDesignSystemProvider>
  );
}

export default App;
